import "./App.scss";
import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { ConfigProvider, Spin } from "antd";
import pt_BR from "antd/es/locale/pt_BR";
import moment from "moment";
import "moment/locale/pt-br";
import { Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import store from "./store";

const loading = () => (
  <div className="animated fadeIn pt-3 text-center"><Spin /></div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./views/Pages/Login"));

const ContratoConstrutorAssinatura = React.lazy(() =>
  import("./views/Contratos/ContratoConstrutorAssinatura")
);

const AssinaturaV2 = React.lazy(() => import("./views/Documentos/AssinaturaV2")
);

const VerificacaoPorCodigo = React.lazy(() =>
  import("./views/Documentos/Verificar/PorCodigo")
);

const VerificacaoPorDocumento = React.lazy(() =>
  import("./views/Documentos/Verificar/PorImportacaoCertificado")
);

const VerificacaoPorCertificadoDigital = React.lazy(() =>
  import("./views/Documentos/Verificar/PorImportacaoDocCertDig")
);

const CadastrarEmpresa = React.lazy(() =>
  import("./views/Pages/CadastroEmpresa")
);

const ContratoConstrutorDownload = React.lazy(() =>
  import("./views/Contratos/ContratoConstrutorDownload")
);

const RedefinirSenha = React.lazy(() =>
  import("./views/Pages/RedefinirSenha")
);

const Assinar = React.lazy(() => import('./views/Documentos/Assinar'));

class App extends Component {
  render() {
    moment.locale("pt-br");
    return (
      <Provider store={store}>
        <ConfigProvider locale={pt_BR}>
          <BrowserRouter>
            <React.Suspense fallback={loading()}>
              <Switch>
                <Route
                  path="/login/:cpf/:senha"
                  exact
                  name="Login Page"
                  render={(props) => <Login {...props} />}
                />
                <Route
                  path="/assinatura/:chaveAcesso"
                  exact
                  name="Login Page"
                  render={(props) => (
                    <ContratoConstrutorAssinatura {...props} />
                  )}
                />
                <Route
                  path="/verificar"
                  exact
                  name="VERIFICAR VIA DOCUMENTO"
                  render={(props) => (
                    <VerificacaoPorDocumento {...props} />
                  )}
                />
                <Route
                  path="/verificar/codigo/:codigoDoc?"
                  exact
                  name="VERIFICAR VIA CÓDIGO"
                  render={(props) => (
                    <VerificacaoPorCodigo {...props} />
                  )}
                />
                <Route
                  path="/verificar/assinaturaCertificado"
                  exact
                  name="VERIFICAR ASSINATURA DIGITAL"
                  render={(props) => (
                    <VerificacaoPorCertificadoDigital {...props} />
                  )}
                />
                <Route
                  path="/download/contrato/:codigoDoc/:chaveAcesso"
                  exact
                  name="BAIXAR CONTRATO"
                  render={(props) => (
                    <ContratoConstrutorDownload {...props} />
                  )}
                />
                <Route
                  path="/empresas/novo-cadastro"
                  exact
                  name="Cadastrar empresa"
                  render={(props) => <CadastrarEmpresa {...props} />}
                />
                <Route
                  path="/redefinirSenha/:token"
                  exact
                  name="Redefinição de senha"
                  render={(props) => (
                    <RedefinirSenha {...props} />
                  )}
                />
                <Route
                  path="/login"
                  exact
                  name="Login Page"
                  render={(props) => <Login {...props} />}
                />
                <Route
                  path="/"
                  name="Dashboard"
                  component={(props) => <DefaultLayout {...props} />}
                />
                <Route path="*" component={() => <h1>Page not found</h1>} />
              </Switch>
            </React.Suspense>
          </BrowserRouter>
          <ToastContainer limit={3} transition={Slide} />
        </ConfigProvider>
      </Provider>
    );
  }
}

export default App;
