import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  storeHorario: ['horario'],
});

const INITIAL_STATE = {
  data: null,
};

const store = (state = INITIAL_STATE, action) => ({
  ...state,
  data: action.horario,
});

export default createReducer(INITIAL_STATE, {
  [Types.STORE_HORARIO]: store,
});
